<template>
  <div class="column card my-4 mx-2">
    <p class="title">Requisições de Gravação</p>
    <b-table
      :data="requestsRecordings"
      class="my-table"
      v-if="requestsRecordings.length"
    >
      <b-table-column label="Solicitante" v-slot="props">
        {{ props.row[1].requester }}
      </b-table-column>
      <b-table-column
        label="Data da Solicitação"
        v-slot="props"
        field="created"
      >
        {{ new Date(props.row[1].created).toLocaleString() }}
      </b-table-column>
      <b-table-column
        label="Período Solicitado"
        v-slot="props"
        field="dateSelect"
      >
        {{ props.row[1].dateSelect }} <br />
        ({{ props.row[1].startHour.substring(0, 5) }} à
        {{ props.row[1].endHour.substring(0, 5) }})
      </b-table-column>
    </b-table>
    <div class="statusOff is-size-4" v-else>Sem dados.</div>
  </div>
</template>
<script>
import firebase from "../../../firebase.config";
export default {
  name: "MordorRequestsHistory",
  data() {
    return {
      requestsRecordings: [],
      historyRef: null,
    };
  },
  methods: {
    FindRequests() {
      const today = new Date(Date.now())
        .toLocaleDateString("pt-br", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        })
        .split("/");

      const historysPath = `mordor-request-recording/${this.GetCurrentUser.activeFlag.toLowerCase()}`;
      this.historyRef = firebase.database().ref(historysPath);
      try {
        this.historyRef.off();
      } catch (error) {
        console.log(error);
      }

      this.historyRef.on("value", (snapshot) => {
        if (!snapshot.val()) {
          this.requestsRecordings = [];
          return;
        }
        this.$store
          .dispatch("database/GetFromDatabase", {
            path: historysPath,
          })
          .then((dates) => {
            if (dates) {
              Object.keys(dates.val()).forEach(() => {
                this.$store
                  .dispatch("database/GetFromDatabase", {
                    path:
                      "mordor-request-recording/" +
                      this.GetCurrentUser.activeFlag.toLowerCase() +
                      "/" +
                      today[2] +
                      "/" +
                      today[1],
                    returnSnapshotVal: true,
                  })
                  .then((result) => {
                    if (result) {
                      const transformed = Object.entries(result).reverse();
                      this.requestsRecordings = transformed;
                    } else {
                      this.requestsRecordings = [];
                    }
                  });
              });
            }
          });
      });
    },
  },
  computed: {
    GetCurrentUser() {
      return this.$store.getters["user/GetCurrentUser"];
    },
  },
  watch: {
    GetCurrentUser: {
      handler() {
        this.historyRef.off();
        this.FindRequests();
      },
      deep: true, //Watch for changes on the entire user object, not just its first level.
    },
    GetUserActiveFlag: {
      handler() {
        this.historyRef.off();
        this.FindRequests();
      },
      deep: true,
    },
  },
  unmounted() {
    this.ref.off();
  },
};
</script>
